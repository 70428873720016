.App {
  min-width: 1880px;
  min-height: 100vh;
  background-image: url(../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .content {
    margin: 0 190px 0 200px;
    display: flex;
    padding-top: 100px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  .left {
    .title {
      font-size: 64px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 96px;
    }

    .fullName {
      font-size: 42px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 63px;
    }

    .descs {
      padding-top: 24px;
      font-size: 28px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 44px;
      letter-spacing: 1px;
      opacity: 0.49;
    }

    .btn {
      width: 350px;
      height: 80px;
      background: #246EFF;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 133px;
      cursor: pointer;

      span {
        font-size: 30px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 2px;
      }
    }

    .terms {
      margin-top: 24px;
      width: 350px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 21px;
      text-align: center;
      opacity: 0.69;
    }

    .version {
      width: 370px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 21px;
      text-align: center;
      margin-top: 14px;
      opacity: 0.69;
    }

    .corp {
      width: 430px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 21px;
      text-align: center;
      margin-top: 14px;
      opacity: 0.69;
    }
  }

  .right {
    margin-left: 70px;

    .boxShow {
      width: 700px;
      height: 396px;
      background-image: url(../assets/snapshot.jpg);
      background-size: 100% 100%;
      border-radius: 5px;
      margin-top: 50px;
    }
  }
}