.Header {
  height: 144px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 200px;

  .left {
    display: flex;
    align-items: center;

    .logo {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      border-radius: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin: 0;
      }

      .fullName {
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        margin: 0;
      }
    }
  }

  .right {
    .btn {
      width: 250px;
      height: 72px;
      background: #246EFF;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 31px;
        letter-spacing: 1px;
      }
    }
  }
}